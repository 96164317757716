import React from 'react'

import { Link, navigate } from "gatsby"

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Stack, Button, Typography } from '@mui/material';

import { FaFile, FaDownload } from "react-icons/fa";

import Layout from '../components/Layout'
import AppDetail from '../components/AppDetail'

import { listTesting } from "../data"

import translateManager from "../translations/translateManager"

export default function testing() {

    const dlFile = (filename) => {
        const link = document.createElement('a');
        let filepath = process.env.APK_DOWNLOAD_PATH_PREFIX + "/testing/" + filename;
        console.log(filepath);
        link.href = filepath;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      };

    

    return (
        <Layout>
            {/* <Typography style={{margin: "1em 0 2em 0"}} align="center" variant="string" component="div">
            {translateManager.getText("TESTING_INFO_TEXT")}
            </Typography> */}

             
            <Typography style={{margin: "1em 0 2em 0"}} align="center" variant="string" component="div">
                Rendez vous sur firebase pour télécharger nos applications de test
            </Typography>
            <Link to={"https://console.firebase.google.com/"} target="_blank">
                <div style={{textAlign: "center", padding: "1em"}}>
                https://console.firebase.google.com/
                </div></Link>
            
            
            
        </Layout>
    )
}
